import { Component, WorkshopSidebar } from "@components";
import { Container, StyledDashboard } from "@util/standard";
import { PageProps, graphql } from "gatsby";
import React, { useEffect, useState } from "react";

import { PageContext } from "@util/types";
import { Query } from "firebase/firestore";
import { useFirestoreDocFuncs } from "@util/useFirestoreDocFuncs";
import { useStore } from "@state/store";

interface Props extends PageProps {
  data: Query;
  pageContext: PageContext;
}

const SectionTemplate = ({ data, pageContext }: Props) => {
  const { user } = useStore();
  const [expanded, setExpanded] = useState(true);

  // const { sanityWorkshop } = data;

  const pageContextToFirebase = {
    currentPath: pageContext.pagePath,
    workshopContext: pageContext.workshopContext,
  };

  const onRequestStatus = {
    success: () => {},
    failed: () => {},
  };

  const { update, status } = useFirestoreDocFuncs(
    "users",
    user?.uid,
    { pageContext: pageContextToFirebase },
    onRequestStatus,
  );

  useEffect(() => {
    // if (user) {
    update();
    // }
  }, []);

  return (
    <>
      {/* <StyledDashboard expanded={expanded}>
        <WorkshopSidebar
          expanded={expanded}
          setExpanded={setExpanded}
          workshopContext={pageContext.workshopContext}
          currentPage={pageContext.pageId}
        />
        <Container
          display="flex"
          flexDirection="column"
          margin="50px auto"
          position="relative"
          width="100%"
        >

        </Container>
      </StyledDashboard> */}
    </>
  );
};

export default SectionTemplate;

// export const query = graphql`
//   query SectionQuery($slug: String) {
//     sanityWorkshop(sections: { elemMatch: { slug: { current: { eq: $slug } } } }) {
//       blocks {
//         ...sanityColumnBlock
//       }
//     }
//   }
// `;
